import React from 'react'
import get from 'lodash.get'
import ItemRow from './ItemRow'
import { getLocale } from '../../utils/location'
import './styles.scss'

const HomepageList = ({ articles, introDuration, lastItemColor }) => {
  const locale = getLocale()
  const items = articles
    .filter(item => !!item.article)
    .map(item => {
      const article = item.article
      const subtitle = get(article, 'label[0].text', null)
      const titleHome = get(article, 'title_home[0].text', null)
      const title = get(article, 'title[0].text', null)

      return {
        id: article._meta.uid,
        href: `${locale}/article/${article._meta.uid}`,
        title: titleHome || title,
        subtitle,
        type: !!article.is_in_agenda ? 'news' : 'article',
      }
    })

  return (
    <div className="articles-list">
      {items.map((item, index) => (
        <ItemRow
          key={item.id}
          href={item.href}
          title={item.title}
          subtitle={item.subtitle}
          type={item.type}
          delay={introDuration + (index + 1) / 6}
          isLastItem={index + 1 === items.length}
          lastItemColor={lastItemColor}
        />
      ))}
    </div>
  )
}

export default HomepageList
