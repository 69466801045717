import React from 'react'
import get from 'lodash.get'
import words from 'lodash.words'
import flatten from 'lodash.flatten'
import HomepageList from '../HomepageList'
import IntroAnimation from '../IntroAnimation'
import './style.scss'

const isClient = typeof window === 'object'

const Home = ({ data, langKey }) => {
  const text = get(data, 'intro_text[0].text', '')
  const background = get(data, 'intro_background', null)
  const color = get(data, 'intro_color', '#F00')
  const lastItemColor = get(data, 'last_item_color', '#f73c02')
  const paragraphs = text
    .split(/[\r\n\t]+/gm)
    .map(paragraph => words(paragraph, /[^, ]+/g))
  const wordCount = flatten(paragraphs).length
  const introDuration =
    isClient && !sessionStorage.getItem('aha_intro_ended')
      ? 4 + wordCount * 0.15
      : 0

  const introData = { paragraphs, background, color }

  return (
    <div className="home">
      {!!paragraphs.length && !!background && (
        <IntroAnimation data={introData} />
      )}
      <HomepageList
        articles={data.featured_articles}
        langKey={langKey}
        introDuration={introDuration}
        lastItemColor={lastItemColor}
      />
    </div>
  )
}

export default Home
