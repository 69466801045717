import React from 'react'
import { TimelineLite, Power3 } from 'gsap'

const isClient = typeof window === 'object'

class IntroAnimation extends React.Component {
  constructor(props) {
    super(props)

    this.backgroundRef = React.createRef()
    this.introTextRef = React.createRef()
    this.introWrapperRef = React.createRef()
  }

  state = {
    isVisible: isClient ? !sessionStorage.getItem('aha_intro_ended') : true,
  }

  onAnimEnd = () => {
    if (!isClient) {
      return
    }

    const { isVisible } = this.state
    this.setState({ isVisible: !isVisible })
    sessionStorage.setItem('aha_intro_ended', true)
  }

  initRevealAnimation = () => {
    const tl = new TimelineLite({ onComplete: this.onAnimEnd })

    tl.fromTo(
      this.backgroundRef.current,
      2,
      { autoAlpha: 0 },
      {
        autoAlpha: 1,
        delay: 0.3,
        ease: Power3.easeOut,
      }
    )
      .staggerFromTo(
        this.introTextRef.current.querySelectorAll('.word'),
        0.6,
        { autoAlpha: 0 },
        { autoAlpha: 1 },
        0.15,
        '-=1'
      )
      .to(this.introWrapperRef.current, 2, {
        autoAlpha: 0,
        delay: 0.5,
        ease: Power3.easeIn,
      })
  }

  componentDidMount() {
    if (!!this.introTextRef.current) {
      this.initRevealAnimation()
    }
  }

  render() {
    const { isVisible } = this.state
    const {
      data: { paragraphs, background, color },
    } = this.props

    const classNames = `intro--animation ${!isVisible &&
      'intro--animation__hidden'}`

    return (
      <div ref={this.introWrapperRef} className={classNames}>
        <div ref={this.introTextRef} className="intro__text">
          {paragraphs.map(p => (
            <p key={p}>
              {p.map(
                word =>
                  !!word && (
                    <span key={word} className="word" style={{ color }}>
                      {word}
                    </span>
                  )
              )}
            </p>
          ))}
        </div>

        <div
          ref={this.backgroundRef}
          style={{ backgroundImage: `url(${background.url})` }}
          className="intro__background"
        />
      </div>
    )
  }
}

export default IntroAnimation
