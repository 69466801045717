import React from 'react'
import { Link } from 'gatsby'
import AutoScrollerWrapper from './AutoScrollerWrapper'
import './styles.scss'

const ItemRow = ({
  title_home,
  title,
  subtitle,
  href,
  delay,
  type,
  isLastItem,
  lastItemColor,
}) => {
  const finalTitle = title_home || title
  let titleStyle = {}
  let subtitleStyle = {}

  if (!!subtitle) {
    subtitleStyle = { minWidth: `${(subtitle.length || 0) * 10}px` }
  }

  if (isLastItem) {
    titleStyle = { color: lastItemColor }
    subtitleStyle.color = lastItemColor
  }

  return (
    <div className={`article article--${type}`}>
      <AutoScrollerWrapper delay={delay}>
        <Link to={href}>
          <div>
            <div className="title" style={titleStyle}>
              {finalTitle}
            </div>
            {!!subtitle && (
              <div className="category" style={subtitleStyle}>
                {subtitle}
              </div>
            )}
          </div>
        </Link>
      </AutoScrollerWrapper>
    </div>
  )
}

export default ItemRow
